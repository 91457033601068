<template>
 <v-theme-provider dark>
  <base-section
    id="washaddit"
    class="accent"
    space="36"
  >
    <v-spacer />

    <div v-if="$route.name=='세병첨가제'">
      <v-tabs
        class="hidden-sm-and-down"
        optional
        background-color="transparent"
      >
        <v-tab
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          :ripple="false"
          class="font-weight-bold"
          min-width="96"
          text
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-container>
      <base-section-heading title="세병첨가제란?" />

      <v-container
      class="text-center"
      >
          세벙첨가제는 음료,주류,제약 등의 공정 중 세병기에 사용하여, 세병의 효과를 향상시키기 위한 첨가제 입니다.<br><br>
          세병기의 Caustic Zone에 가성소다 만을 사용할 경우 병의 침식작용에 의한 Scratch, <br>
          약한 습윤력에 의한 미약한 Rinse 효과, <br>
          스케일 형성에 따른 세병효율저하 및 살균작용 무력화, <br>
          완충작용 부재에 따른 가성용액 농도의 쉬운 변화,<br>
          약한 유화분산력에 의한 오물 재부착 등의 문제가 발생합니다.<br>
          이에 세병첨가제는 가성용액의 장점은 살리고, 문제점을 해결하기 위한 첨가제 입니다.<br>
          <br>
          최신 세병 Solution으로 타생한 남강의 세병첨가제는 다음과 같은 특성(P7)이 있습니다.<br><br>
          P1-알카리 고온 안정성 우수<br>
          P2-가성용액의 수명연장<br>
          P3-강력한 오물 분산 효과<br>
          P4-우수한 헹굼력<br>
          P5-유리병 마모 방지<br>
          P6-병의 광택 효과<br>
          P7-페수처리 용이<br>
      </v-container>
      <v-container>
      </v-container>

      <v-container>
      </v-container>
      <base-section-heading title="제품군" />
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                세병기 가성용액 첨가제
              </v-subheader>
            </v-card>
          </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                세병기 린스제
              </v-subheader>
            </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <v-card
            class="accent"
            >
              <v-subheader
              :inset="inset"
              >
                세병기 전용 소포제(비실리콘계)
              </v-subheader>
            </v-card>
        </v-col>
    </v-container>
  </base-section>
 </v-theme-provider>
</template>

<script>
  export default {
    name: 'Sectionwashaddit',
    data: () => ({
      drawer: null,
      items: [
        'Cip',
        'Cop',
        '세병첨가제',
        '컨베이어 윤활제',
        '생수공장용',
        '수질용',
      ],
      inset: false,
    }),
    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}
</style>
